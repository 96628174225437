@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');

html {
    font-weight: 300;
    font-size: 15px;
}
@media screen and (max-width: 768px) {
    html {
        font-size: 14px;
    }
}
@media screen and (max-width: 576px) {
    html {
        font-size: 13px;
    }
}


body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("./images/page_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

#root{
    color: white;
    font-family: 'Quicksand', sans-serif;
}

.FooterNavbarIcon{
    padding: 10px 15px 10px 0px;
}
.FooterNavbarIcon svg{
    color: greenyellow;
    min-width: 30px;
    min-height: 30px;
    transition: color 0.5s ease-out;
}
.FooterNavbarIcon svg:hover{
    color: white;
}
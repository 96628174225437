.Contact{
    padding-bottom: 100px;
}

.ContactTitle h4{
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 25px;
}
.ContactText{
    font-size: 1.3rem;
    padding-bottom: 30px;
}
.ContactButton{
    color: greenyellow;
    font-size: 1.5rem;
    font-weight: 700;
    width: fit-content;
    border-radius: 10px;
    background-color:  rgba(173, 255, 47, 0.10);
    padding: 10px 25px;
    border: 2px solid rgba(173, 255, 47, 0.5);
    transition: background-color 0.3s ease-in, border 0.5s ease-out;
}
.ContactButton:hover{
    cursor: pointer;
    border: 2px solid rgba(173, 255, 47, 1);
    background-color: rgba(173, 255, 47, 0.25);
}
.Contact-links{
    padding-top: 50px;
}
.Contact-links svg{
    width: 2.5rem !important;
    height: auto;
}
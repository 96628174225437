.Projects{
    padding-bottom: 60px;
}

.ProjectsTitle h4{
    font-size: 2rem;
    font-weight: 700;
}
.ProjectsText{
    padding-top: 20px;
    padding-bottom: 50px;
    font-size: 1rem;
}
.ProjectsCard{
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    margin-bottom: 50px;
    transition: background-color 0.5s ease-out;
}
.ProjectsCard:hover{
    background-color: rgba(173, 255, 47, 0.1);
}
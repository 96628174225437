.CustomNavbar{
    padding-top: 50px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    font-weight: 700;
    color: white;
}

.CustomNavbarItem{
    padding: 8px 10px;
    background-color: rgba(173, 255, 47, 0);
    border-radius: 5px;
    border: 1px solid rgba(173, 255, 47, 0);
    transition: background-color 0.5s ease-out, border 0.3s ease-in;
}
.CustomNavbarItem:hover{
    cursor: pointer;
    background-color: rgba(173, 255, 47, 0.15);
    border: 1px solid greenyellow;
}

.CustomNavbarIcon {
    color: greenyellow;
    padding-right: 15px;
}

.CustomNavbarMobile{
    display: flex;
    justify-content: center;
}
.CustomNavbarMobile .CustomNavbarItem{
    padding-top: 0px;
    float: left;
    width: fit-content;
    background-color: none !important;
    border: none !important;
    transition: none !important;
}
.CustomNavbarMobile .CustomNavbarItem:hover{
    background-color: none !important;
    border: none !important;
}
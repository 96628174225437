@media screen and (max-width: 768px) {
    .Hero{
        min-height: auto !important;
    }
}
@media screen and (max-width: 576px) {
    .Hero{
        min-height: auto !important;
    }
}
.Hero{
    min-height: 100vh;
}
.Hero h1{
    font-weight: 700;
    font-size: 2.6rem;
}
.Hero h3{
    font-size: 1.2rem;
    color: greenyellow;
}
.Hero p{
    margin-top: 10px;
    font-size: 0.9rem;
    color: lightgray;
}
.Hero-links{
    padding-top: 10vh;
}
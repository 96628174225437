.Skills{
    padding-bottom: 100px;
}
.SkillsTitle h4{
    font-size: 2rem;
    font-weight: 700;
}
.SkillsText{
    padding-top: 20px;
    font-size: 1rem;
}
.SkillsTags{
    margin-top: 20px;
}
.SkillsTagsCategory{
    width: 100%;
    margin-bottom: 40px;
}
.SkillsTagsCategory h6{
    font-weight: 700;
}
.SkillsTags-item{
    color: greenyellow;
    width: fit-content;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center !important;
    border-radius: 10px;
    background-color: rgba(173, 255, 47, 0.15);
    padding: 5px 15px;
    margin: 5px 5px;
    float: left;
    transition: background-color 0.3s ease-in, border 0.5s ease-out, color 0.3s ease, font-weight 0.3s ease;
}
.SkillsTags-item:hover{
    cursor: default;
    color: black;
    background-color: rgba(173, 255, 47, 0.9);
}